<template>
  <b-modal
    :id="`available-modal-${timeData.id}`"
    hide-footer
    hide-header
    size="lg"
    @hidden="handleClose"
  >
    <div
      v-if="
        (newData && newData.availability !== availability) || fromParticipants
      "
    >
      <div class="headerClose">
        <i class="fa fa-times" @click="handleClose" aria-hidden="true"></i>
      </div>
      <div class="forMultipleRequest">
        <div
          class="selectedMember forRequestMessage"
          v-for="user in selectedUsers"
          :key="user.id"
        >
          <img :src="user.details.prof_pic" alt="Member Picture" />
          <div>
            <span class="userName">{{ user.details.name }} </span>
            <span class="userPosition">{{ user.details.job_title }}</span>
            <span class="userCompany">{{ user.details.company }}</span>
          </div>
        </div>
      </div>
      <div v-if="!fromParticipants" class="mt-3">
        <div v-if="selectedUsers.length === 1" class="userNotAvailable ml-3">
          {{ $lodash.get(selectedUsers[0], "details.name") }}
          is not available on <br v-if="isMobileScreen" />
          <span style="color: #333">
            {{ moment(day).format("dddd DD MMM") }} •
            {{ timeData.start.slice(0, 5) }} -
            {{ timeData.end.slice(0, 5) }} </span
          >.
        </div>
        <div v-else class="userNotAvailable ml-3">
          {{ newData.availability }} on
          <span style="color: #333">
            {{ moment(day).format("dddd DD MMM") }} •
            {{ timeData.start.slice(0, 5) }} - {{ timeData.end.slice(0, 5) }}
          </span>
        </div>
        <br v-if="isMobileScreen" />
        <p class="userNotAvailable ml-3">
          Please select one of the free time-slots listed below.
        </p>
      </div>
      <div v-if="!isMobileScreen">
        <b-container class="bv-example-row">
          <b-row>
            <b-col sm="2" class="bCol"><h3>TIME SLOT</h3></b-col>
            <b-col sm class="bCol" v-for="day in days" :key="day">
              <h3>
                {{ moment(day).format("dddd DD MMM") }}
              </h3>
            </b-col>
          </b-row>
        </b-container>
        <b-container class="bv-example-row" v-for="time in times" :key="time">
          <b-row>
            <b-col sm="2" class="reqMeeting">
              <h3>
                {{ moment(`${days[0]} ${time}`).format("HH:mm") }} -
                {{
                  moment(
                    new Date(`${days[0]} ${time}`).getTime() + 1800000
                  ).format("HH:mm")
                }}
              </h3>
            </b-col>
            <b-col
              class="reqMeeting"
              v-for="(dayData, index) in meetings[time]"
              :key="index"
            >
              <div
                v-if="dayData"
                :id="
                  dayData.availability !== availability && 'notAvailableText'
                "
              >
                <!-- <router-link
                  v-if="
                    dayData.agenda &&
                    dayData.availability === availability &&
                    dayData.agenda_details.type !== 'static'
                  "
                  :to="{
                    name: 'Agenda',
                    params: {
                      day: days[index],
                      agendaUrl: dayData.agenda,
                    },
                  }"
                  class="agendaType"
                  v-b-popover.hover.top="
                    dayData.agenda_details.start_time.slice(0, 5) +
                    ' - ' +
                    dayData.agenda_details.end_time.slice(0, 5)
                  "
                >
                  {{ agendaTypes[dayData.agenda_details.type] || "" }}
                </router-link>
                <span v-else>{{ null }}</span> -->
                <div v-if="dayData.availability !== availability">
                  <p id="notAvailableText">
                    {{ dayData.availability }}
                  </p>
                </div>
                <div v-if="dayData.availability === availability">
                  <p id="requestMeeting">
                    <InvitationFromModal
                      :users="users"
                      :selectedUsers="selectedUsers"
                      v-model="meetingDaysMap[time][index]"
                      :day="days[index]"
                    />
                  </p>
                </div>
              </div>
              <div v-else>{{ null }}</div>
              <!-- <div
                v-if="isExpired(days[index], $lodash.get(dayData, 'start', ''))"
                class="expired"
              >
                EXPIRED
              </div> -->
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div v-else class="bv-example-row" v-for="(day, i) in days" :key="day">
        <b-card no-body>
          <b-card-header header-tag="header" class="p-3" role="tab">
            <div v-b-toggle="`${day}${i}-${i}`" class="text-center">
              {{ moment(day).format("DD MMMM") }}
              <i class="fas fa-chevron-up" />
              <i class="fas fa-chevron-down" />
            </div>
          </b-card-header>
          <b-collapse
            :id="`${day}${i}-${i}`"
            accordion="mp-head-accordion"
            role="tabpanel"
          >
            <b-card-body class="p-0">
              <b-row
                class="bv-example-row m-0"
                v-for="(dayData, index) in meetingDaysMapForMobile[day]"
                :key="dayData.id"
                :style="
                  dayData.availability !== availability &&
                  'background-color:#b8b8b8'
                "
              >
                <div class="reqMeeting">
                  <h3>
                    <span>{{
                      moment(`${day} ${dayData.start}`).format("HH:mm")
                    }}</span>
                    -
                    <span>{{
                      moment(`${day} ${dayData.end}`).format("HH:mm")
                    }}</span>
                  </h3>
                </div>
                <div class="reqMeeting">
                  <div
                    :id="
                      dayData.availability !== availability &&
                      'notAvailableText'
                    "
                  >
                    <!-- <router-link
                      v-if="
                        dayData.agenda &&
                        dayData.availability === availability &&
                        dayData.agenda_details.type !== 'static'
                      "
                      :to="{
                        name: 'Agenda',
                        params: {
                          day,
                          agendaUrl: dayData.agenda,
                        },
                      }"
                      class="agendaType"
                      v-b-popover.hover.top="
                        dayData.agenda_details.start_time.slice(0, 5) +
                        ' - ' +
                        dayData.agenda_details.end_time.slice(0, 5)
                      "
                    >
                      {{ agendaTypes[dayData.agenda_details.type] || "" }}
                    </router-link>
                    <span v-else>{{ null }}</span> -->
                    <div v-if="dayData.availability !== availability">
                      <p id="notAvailableText">
                        {{ dayData.availability }}
                      </p>
                    </div>
                    <div v-if="dayData.availability === availability">
                      <p id="requestMeeting">
                        <InvitationFromModal
                          :users="users"
                          :selectedUsers="selectedUsers"
                          v-model="meetingDaysMapForMobile[day][index]"
                          :day="day"
                        />
                      </p>
                    </div>
                  </div>
                  <!-- <div v-if="isExpired(day, dayData.start)" class="expired">
                    EXPIRED
                  </div> -->
                </div>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import InvitationFromModal from './InvitationFromModal.vue';

export default {
  name: 'AvailableSlotsModal',
  components: {
    InvitationFromModal,
  },
  props: {
    selectedUsers: {
      type: Array,
      default: () => [],
    },
    handleClose: {
      type: Function,
    },
    newData: {
      default: () => {},
    },
    day: {
      type: String,
      default: '',
    },
    timeData: {
      type: Object,
      default: () => ({ id: 'participant' }),
    },
    users: {
      type: Array,
      default: () => [],
    },
    fromParticipants: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      availability: 'Available',
      agendaTypes: {
        static: 'Static',
        activity: 'Activity',
        panel_session: 'Panel Session',
      },
    };
  },

  computed: {
    ...mapGetters({
      meetings: 'meetingPlanner/meetings',
      days: 'meetingPlanner/days',
      times: 'meetingPlanner/times',
      meetingDaysMap: 'meetingPlanner/meetingDaysMap',
      event: 'events/fetchEvent',
      isMobileScreen: 'isMobileScreen',
      meetingDaysMapForMobile: 'meetingPlanner/meetingDaysMapForMobile',
    }),
  },

  methods: {
    moment(date) {
      return moment(new Date(date).toUTCString());
    },
    isExpired(day, time) {
      return new Date(`${day}, ${time}`).getTime() < new Date().getTime();
    },
  },
};
</script>

<style src="./modals.css" scoped></style>
<style src="./SingleMemberInvitation.css" scoped></style>
<style src="./StylesForMobile.css" scoped></style>
<style scoped>
.fa-times{
  cursor: pointer;
}
.headerClose {
  position: absolute;
  top: 1rem;
  right: 1.6rem;
  text-align: right;
  color: #585555;
  font-size: 20px;
  z-index: 1;
}
.forMultipleRequest {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
}
.agendaType {
  color: #aaa !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.agendaType:hover {
  background-color: #bd9d5f;
  color: #fff !important;
}
.reqMeeting > div {
  grid-template-columns: 45px 1fr;
}
.reqMeeting > h3 {
  font-size: 15px;
}
.expired {
  background-color: #7f7f7fd4;
  padding-top: 9px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
}
</style>
